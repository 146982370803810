module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"backgroundColor":"none","maxWidth":900,"linkImagesToOriginal":false,"disableBgImage":true,"wrapperStyle":"margin: 1.5rem 0;"}},{"resolve":"gatsby-remark-double-brackets-link","options":{"titleToURLPath":"/home/insutanto/projects/node/gatsby-theme-code-notes/gatsby-theme-code-notes/src/utils/resolve-url.js","stripBrackets":true}}],"remarkPlugins":[null,null,null,[null,{"style":"smart"}],null],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/insutanto/projects/node/gatsby-theme-code-notes/example"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-code-notes/gatsby-browser.js'),
      options: {"plugins":[],"contentPath":"code-notes","basePath":"/","gitRepoContentPath":"https://github.com/Insutanto/gatsby-theme-code-notes/tree/insutanto-2.3.0/example/code-notes/","showDescriptionInSidebar":true,"showThemeInfo":true,"logo":"https://avatars.githubusercontent.com/u/19406290","openSearch":{"siteShortName":"Insutanto Code Notes","siteUrl":"https://programming.insutanto.net","siteTags":"programming","siteContact":"https://twitter.com/insutantow","siteDescription":"Insutanto Code Notes"},"showDate":true},
    }]
