// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---gatsby-theme-code-notes-src-templates-404-js": () => import("./../../../../gatsby-theme-code-notes/src/templates/404.js" /* webpackChunkName: "component---gatsby-theme-code-notes-src-templates-404-js" */),
  "component---gatsby-theme-code-notes-src-templates-note-js": () => import("./../../../../gatsby-theme-code-notes/src/templates/Note.js" /* webpackChunkName: "component---gatsby-theme-code-notes-src-templates-note-js" */),
  "component---gatsby-theme-code-notes-src-templates-notes-js": () => import("./../../../../gatsby-theme-code-notes/src/templates/Notes.js" /* webpackChunkName: "component---gatsby-theme-code-notes-src-templates-notes-js" */),
  "component---gatsby-theme-code-notes-src-templates-tag-page-js": () => import("./../../../../gatsby-theme-code-notes/src/templates/TagPage.js" /* webpackChunkName: "component---gatsby-theme-code-notes-src-templates-tag-page-js" */)
}

